
import TelaExibirDetalhes from '../TelaExibirDetalhes.vue';

export default {
  name: "tela-exibir-sucesso-safra",
  data: () => ({
    // tutorialIs: false,
    detalhe_proposta: false,
  }),
  methods: {},
  props: [
    "openWhatsapp",
    "codigo_proposta",
    "openProposta",
    "pacote_selecionado",
    "url",
  ],
  components: { TelaExibirDetalhes }
};
