
export default {
  name: "tela-exibir-detalhes",
  data: () => ({}),
  methods: {
    formatarReal(value) {
      var valor = value;
      valor = valor + "";
      valor = parseInt(valor.replace(/[\D]+/g, ""));
      valor = valor + "";
      valor = valor.replace(/([0-9]{2})$/g, ",$1");

      if (valor.length > 6) {
        valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      }

      return valor;
    },
  },
  filters: {
    parentheses(val) {
      return val ? `(${val})` : ''
    },
    currency(val) {
      return val ? `R$ ${val}` : ''
    },
    percent(val) {
      return val ? `${val}%` : ''
    }
  },
  props: ["data", "id", "setSimulacao", "parcelas", "voltar"],
};
