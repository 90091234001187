
import TelaExibirDetalhes from '../TelaExibirDetalhes.vue';

export default {
  name: "tela-exibir-sucesso-facta",
  data: () => ({
    tutorialIs: false,
    detalhe_proposta: false,
    activeView: 'proposta' // proposta | dicas | resumo
  }),
  methods: {
    setView(slug) {
      if (["proposta", "dicas", "resumo"].includes(String(slug)) ) {
        this.activeView = String(slug)
      }
    }
  },
  props: [
    "openWhatsapp",
    "codigo_proposta",
    "openProposta",
    "pacote_selecionado",
    "url",
  ],
  components: { TelaExibirDetalhes }
};
