import axios from "axios";

class CrmAPI {
  constructor() {
    this.api = axios.create({
      baseURL: process.browser ? process.env.BASE_URL + "/crm" : "",
      withCredentials: true
    });
  }

  async getDeal(value) {
    return await this.api.post(`/deal/code/${value}`);
  }

}

export default CrmAPI;
